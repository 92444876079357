var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "landing-central-card",
    [
      _c(
        "v-card",
        {
          staticClass: "forgotten-password-card flexcard",
          class: _vm.$vuetify.breakpoint.smAndUp ? "pa-3" : "pa-0",
          style: _vm.cardStyles,
          attrs: {
            flat: "",
            elevation: _vm.elevation,
            "min-height": _vm.cardHeight
          }
        },
        [
          _vm.loading ? _c("v-card-loader") : _vm._e(),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "", autocomplete: "off" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.askResetPassword($event)
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm11: "", md10: "" } },
                        [
                          _c("h1", { staticClass: "starling-h1" }, [
                            _vm._v(
                              _vm._s(_vm.$t("public.forgotten_password.title"))
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": "",
                        "mt-3": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm11: "", md10: "" } },
                        [
                          _c("h2", { staticClass: "headline" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "public.forgotten_password.subtitle.provider"
                                )
                              )
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "my-3",
                          attrs: { xs12: "", sm11: "", md10: "" }
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: _vm.$vuetify.breakpoint.xsOnly,
                                "justify-center": "",
                                "gap-xs-2": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "social_login",
                                      attrs: { block: "", outline: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.federatedSignIn(
                                            "SignInWithApple"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "$vuetify.icons.starling_social_apple"
                                        )
                                      ]),
                                      _vm._v(
                                        "\n                    Apple\n                  "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "social_login",
                                      attrs: { block: "", outline: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.federatedSignIn("Google")
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "$vuetify.icons.starling_social_google"
                                        )
                                      ]),
                                      _vm._v(
                                        "\n                    Google\n                  "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              false
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm4: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "social_login",
                                          attrs: { block: "", outline: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.federatedSignIn(
                                                "Facebook"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              "$vuetify.icons.starling_social_facebook"
                                            )
                                          ]),
                                          _vm._v(
                                            "\n                    Facebook\n                  "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c("v-flex", { attrs: { xs10: "", sm8: "", md7: "" } }, [
                        _c("p", { staticClass: "border-with-text" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("common.actions.or")))
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": "",
                        "mt-3": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm11: "", md10: "" } },
                        [
                          _c("h2", { staticClass: "headline" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "public.forgotten_password.subtitle.email"
                                )
                              )
                            )
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              id: "field-email",
                              type: "email",
                              rules: _vm.loginRules,
                              label: _vm.$t(
                                "public.forgotten_password.fields.email"
                              )
                            },
                            model: {
                              value: _vm.forgottenEmail,
                              callback: function($$v) {
                                _vm.forgottenEmail = $$v
                              },
                              expression: "forgottenEmail"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.success
            ? _c(
                "v-card-actions",
                {
                  staticClass: "pa-0 pt-3",
                  attrs: { "justify-center": "", "align-center": "" }
                },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": "",
                        "gap-xs-3": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "text-center": "" } },
                        [
                          _c(
                            "primary-button",
                            {
                              attrs: {
                                id: "btn-do-ask-password-reset",
                                type: "submit",
                                large: "",
                                disabled: !_vm.valid || _vm.loading
                              },
                              on: { click: _vm.askResetPassword }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "public.forgotten_password.buttons.reset_password"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.success
            ? _c(
                "v-card-actions",
                { attrs: { "justify-center": "", "align-center": "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            "justify-center": "",
                            "align-center": "",
                            "text-center": ""
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "btn-cancel",
                                flat: "",
                                round: "",
                                disabled: _vm.loading
                              },
                              on: { click: _vm.cancel }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.actions.cancel")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }