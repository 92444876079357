<template>
  <landing-central-card>
    <v-card flat class="forgotten-password-card flexcard" :elevation="elevation" :min-height="cardHeight" :style="cardStyles" :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : 'pa-0'">
      <v-card-loader v-if="loading"></v-card-loader>

      <v-card-text class="pa-0">
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            autocomplete="off"
            @submit.prevent="askResetPassword">

          <v-layout row wrap justify-center align-center>
            <v-flex xs12 sm11 md10>
              <h1 class="starling-h1">{{ $t('public.forgotten_password.title') }}</h1>
            </v-flex>
          </v-layout>

          <v-layout row wrap justify-center align-center mt-3>
            <v-flex xs12 sm11 md10 >
              <h2 class="headline">{{ $t('public.forgotten_password.subtitle.provider') }}</h2>
            </v-flex>

            <v-flex xs12 sm11 md10 class="my-3">
              <v-layout row :wrap="$vuetify.breakpoint.xsOnly" justify-center gap-xs-2>
                <v-flex xs12 sm4>
                  <v-btn class="social_login" block outline @click="federatedSignIn('SignInWithApple')">
                    <v-icon>$vuetify.icons.starling_social_apple</v-icon>
                    Apple
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm4>
                  <v-btn class="social_login" block outline @click="federatedSignIn('Google')">
                    <v-icon>$vuetify.icons.starling_social_google</v-icon>
                    Google
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm4 v-if="false">
                  <v-btn class="social_login" block outline @click="federatedSignIn('Facebook')">
                    <v-icon>$vuetify.icons.starling_social_facebook</v-icon>
                    Facebook
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout row wrap justify-center align-center>
            <v-flex xs10 sm8 md7>
              <p class="border-with-text"><span>{{ $t('common.actions.or') }}</span></p>
            </v-flex>
          </v-layout>

          <v-layout row wrap justify-center align-center mt-3>
            <v-flex xs12 sm11 md10>
              <h2 class="headline">{{ $t('public.forgotten_password.subtitle.email') }}</h2>
              <v-text-field
                  id="field-email"
                  type="email"
                  v-model="forgottenEmail"
                  :rules="loginRules"
                  :label="$t('public.forgotten_password.fields.email')">
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>

      <v-card-actions justify-center align-center v-if="!success" class="pa-0 pt-3">
        <v-layout row wrap justify-center align-center gap-xs-3>
          <v-flex xs12 text-center>
            <primary-button
                id="btn-do-ask-password-reset"
                type="submit"
                large
                :disabled="!valid || loading"
                @click="askResetPassword">
              {{ $t('public.forgotten_password.buttons.reset_password') }}
            </primary-button>
          </v-flex>
<!--          <v-flex xs12 text-center>-->
<!--            <primary-button-->
<!--                id="btn-do-login-otp"-->
<!--                :disabled="!valid || loading"-->
<!--                type="submit"-->
<!--                @click="loginOTP"-->
<!--                large-->
<!--                class="mt-3">-->
<!--              {{ 'Login in with OTP' }}-->
<!--            </primary-button>-->
<!--          </v-flex>-->
        </v-layout>
      </v-card-actions>
      <v-card-actions justify-center align-center v-if="!success">
        <v-layout row wrap justify-center align-center>
          <v-flex xs12 justify-center align-center text-center>
            <v-btn
                id="btn-cancel"
                flat round
                :disabled="loading"
                @click="cancel">
              {{ $t('common.actions.cancel') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </landing-central-card>
</template>

<script>
import vCardLoader from '@/views/components/loader/v-card-loader.vue';
import { VALIDATION_REGEX } from '@/scripts/api';
import LandingCentralCard from '@/views/landing/landing-central-card.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default {
  components: { PrimaryButton, LandingCentralCard, vCardLoader },
  props: [ 'elevation', 'cardHeight', 'cardStyles' ],
  data() {
    return {
      valid: false,
      loading: false,
      forgottenEmail: '',
      error: null,
      success: false,
      loginRules: [
        v => (v && v.length > 0) || this.$t('common.errors.required'),
        v => VALIDATION_REGEX.EMAIL.test(v) || this.$t('common.errors.email'),
        v => v.length <= 254 || this.$t('common.errors.max254'),
      ],
    };
  },
  mounted() {
    this.valid = false;
  },
  methods: {
    askResetPassword() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store.dispatch('identity/forgotPassword', {
          username: this.forgottenEmail,
          clientMetadata: {
            subdomain: this.partner?.subdomain,
            locale: this.locale,
          },
        }).then(res => {
          this.$log.info(res);
          this.$store.state.auth.email_verification.username = this.forgottenEmail;
          this.$store.state.auth.email_verification.password = null;
          return this.$router.push({ name: 'reset_password' });
        }, err => {
          this.$log.error(err);
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    cancel() {
      this.$router.push({ name: 'presentation' });
    },
    federatedSignIn(provider) {
      this.$router.push({ name: 'federated_start', query: { provider } });
    },
  },
};
</script>

<style scoped>
.forgotten-password-card {
  border-radius: 15px;
}

.border-with-text {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid var(--StarlingLightGrey);
  color: var(--StarlingLightGrey);
  line-height: 0.1em;
  margin: 10px 0;
}

.border-with-text span {
  background: #fff;
  padding: 0 10px;
}

.social_login {
  min-height: 45px;
  border-radius: 10px;
}
</style>
